import React from 'react';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { Typography, Card, Col, Row } from 'antd';
import Layout from './../components/Layout';

const AboutMePage = (props) => (
  <Layout>
    <Row gutter={24}>
      <Helmet title={`About Me | Codepanion`} />
      <Col 
        lg={{
          span: 18,
          offset: 3
        }}
        md={{
          span: 18,
          offset: 3
        }}
        sm={{
          span: 24,
          offset: 0
        }}
        xs={{
          span: 24,
          offset: 0
        }}
        >
        <Card title="Hello World :)">
          <Typography.Paragraph>
            Welcome to codepanion.com
          </Typography.Paragraph>
          <Typography.Paragraph>
            My name is Stefan Mitrović and this is my blog where I would like to share ideas and software development experience with you.
          </Typography.Paragraph>
          <Typography.Paragraph>
            I am a javascript software engineer based in Nis, Serbia. I actively seek out new technologies and love turning ideas into code.
            You can find me on my <a target="_blank" href="https://www.youtube.com/channel/UCkhit0YHPDVvqogIly9yBLQ">youtube channel</a> or you can write me on <a target="_blank" href="https://www.linkedin.com/in/stefan-mitrovic-aa5841138">linkedin</a>.
          </Typography.Paragraph>
        </Card>
      </Col>
    </Row>
  </Layout>
)

export default AboutMePage
